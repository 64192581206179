import { browserName, isMobile, osName } from 'react-device-detect';
import { EPublisherWebhookEventType, ELoginMethods, ELoginEvents, ELocalStorageKeys } from '../constants/enums';
import useApi from './useApi';
import { localStorageUtil } from 'utils';
import useCustomEvents from './useCustomEvents';

export default function useLoginEvents() {
  const customEvents = useCustomEvents();
  const API = useApi({
    platform: isMobile ? osName : browserName,
  });

  const sendPublisherWebhookLoginEvent = async ({
    eventName,
    method,
    options
  }: {
    eventName: ELoginEvents,
    method?: ELoginMethods,
    options?: Record<string, any>
  }) => {
    try {
      API.sendPublisherWebhookEvent.mutate({
        eventType: EPublisherWebhookEventType.LOGIN,
        data: {
          eventName,
          method,
          source: sessionStorage.getItem('source') || 'browser app',
          ...options,
          ...customEvents.getEventsSharedProps()
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const recordLoginStartTime = (): void => {
    localStorageUtil.set(ELocalStorageKeys.LOGIN_START_TIME, new Date().getTime());
  }

  const calcLoginDuration = (): number => {
    const loginEndTime = new Date().getTime();
    const loginStartTime = localStorageUtil.get(ELocalStorageKeys.LOGIN_START_TIME);
    return Math.round((loginEndTime - loginStartTime) / 1000);
  }

  return {
    sendPublisherWebhookLoginEvent,
    recordLoginStartTime,
    calcLoginDuration
  };
}
