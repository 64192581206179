import { ELoginMode } from 'constants/enums';
import { Observable } from 'state/observable/observable.state.service';

class LoginStateService {
  readonly showCookiesOverlay = new Observable<boolean>(false);
  readonly loggingWithProofKey = new Observable<boolean>(false);
  readonly proofKey = new Observable<string>('');
  readonly iframeUrl = new Observable<string>('');
  readonly otpToken = new Observable<string>('');
  readonly mode = new Observable<ELoginMode>(ELoginMode.PROVIDERS);
  readonly showInfoModal = new Observable<boolean>(false);
  readonly hasConsent = new Observable<boolean>(true);
  readonly shouldShowTermsAlert = new Observable<boolean>(false);
  readonly isTermsAlertShaking = new Observable<boolean>(false);

  clear = () => {
    this.showCookiesOverlay.set(false);
    this.loggingWithProofKey.set(false);
    this.proofKey.set('');
    this.iframeUrl.set('');
    this.otpToken.set('');
    this.mode.set(ELoginMode.PROVIDERS);
    this.showInfoModal.set(false);
    this.hasConsent.set(true);
    this.shouldShowTermsAlert.set(false);
    this.isTermsAlertShaking.set(false);
  };
  setShowCookiesOverlay = (show: boolean) => this.showCookiesOverlay.set(show);
  setLoggingWithProofKey = (isLogginWithProofkey: boolean) =>
    this.loggingWithProofKey.set(isLogginWithProofkey);
  setProofKey = (key: string) => this.proofKey.set(key);
  setIframeUrl = (url: string) => this.iframeUrl.set(url);
  setOtpToken = (token: string) => this.otpToken.set(token);
  setMode = (newMode: ELoginMode) => this.mode.set(newMode);
  setShowInfoModal = (show: boolean) => this.showInfoModal.set(show);
  setHasConsent = (consent: boolean) => this.hasConsent.set(consent);
  setShouldShowTermsAlert = (show: boolean) => this.shouldShowTermsAlert.set(show);
  setIsTermsAlertShaking = (shaking: boolean) => this.isTermsAlertShaking.set(shaking);
}
export const loginStateService = new LoginStateService();
