import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLoginPage } from '../useLoginPage.hook';
import { useLoginState } from 'state/hooks/login.state.hook';

interface ISimpleTermsProps {
  privacyPolicyLink?: string;
  termsAndConditionsLink?: string;
}

export const SimpleTerms: React.FC<ISimpleTermsProps> = ({
  privacyPolicyLink,
  termsAndConditionsLink,
}) => {
  const { publisherMetaData } = useLoginPage();
  const { openInfoModal } = useLoginState();
  const { t } = useTranslation();
  const openInNewTab = publisherMetaData?.storeTheme?.login?.openLinksInNewTab || false;

  return (
    <span data-testid="login-page-terms-block" className="terms-wrapper">
      {t('login.byLoggingIn')}{' '}
      <span
        className={'login-link'}
        onClick={() => {
          openInNewTab
            ? window.open(privacyPolicyLink, '_blank')
            : openInfoModal(privacyPolicyLink);
        }}
      >
        {t('login.privacyPolicy')}
      </span>{' '}
      {t('login.and')}{' '}
      <span
        className={'login-link'}
        onClick={() => {
          openInNewTab
            ? window.open(termsAndConditionsLink, '_blank')
            : openInfoModal(termsAndConditionsLink);
        }}
      >
        {t('login.termsAndConditions')}
      </span>
    </span>
  );
};
