import { OfferData } from 'constants/apiResponses.types';
import { Observable } from './observable';
import { localStorageUtil } from '../utils';
import { ELocalStorageKeys } from '../constants/enums';

class AppStateService {
  readonly displayLoader = new Observable<boolean>(true);
  readonly showConfetti = new Observable<boolean>(false);
  readonly failedHashValidation = new Observable<boolean>(false);
  readonly selectedOffer = new Observable<OfferData | null>();
  readonly isIntegrationProfilesEnabled = new Observable<boolean>(
    localStorageUtil.get(ELocalStorageKeys.INTEGRATION_PORFILE) !== null
  );
  readonly backgroundProps = new Observable<object>({});
  readonly collectedProductsImages = new Observable<string[]>([]);

  clear = () => {
    this.displayLoader.set(false);
    this.failedHashValidation.set(false);
    this.showConfetti.set(false);
    this.selectedOffer.set(null);
    this.backgroundProps.set({});
    this.collectedProductsImages.set([]);
  };

  setDisplayLoader = (showLoader: boolean) => this.displayLoader.set(showLoader);
  setShowConfetti = (show: boolean) => this.showConfetti.set(show);
  setSelectedOffer = (offer: OfferData | null) => this.selectedOffer.set(offer);
  setFailedHashValidation = (failed: boolean) => this.failedHashValidation.set(failed);
  setIsIntegrationProfilesEnabled = (showIntegrationProfiles: boolean) =>
    this.isIntegrationProfilesEnabled.set(showIntegrationProfiles);
  setBackgroundProps = (background: object) => this.backgroundProps.set(background);
  setCollectedProductsImages = (images: string[]) => this.collectedProductsImages.set(images);
}

export const appStateService = new AppStateService();
