import React, { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { StyledProductsAnimationWrapper } from './style';
import { useAppState } from 'state/hooks/app.state.hook';
import { ECheckoutPageEvent } from 'constants/enums';

export const ProductsCollectAnimation: React.FC = () => {
  const { collectedProductsImages, setCollectedProductsImages, shouldDisplayProductsAnimation } =
    useAppState();
  const [isExiting, setIsExiting] = useState<boolean>(false);

  useEffect(() => {
    if (collectedProductsImages && collectedProductsImages.length > 0) setIsExiting(true);
  }, [collectedProductsImages?.length]);

  useEffect(() => {
    if (collectedProductsImages && collectedProductsImages?.length > 0) {
      setTimeout(() => setCollectedProductsImages([]), 700);
    }
  }, [collectedProductsImages?.length]);

  useEffect(() => {
    const eventHandler = (massageEvent: any) => {
      if (massageEvent.origin !== window.location.origin) return;
      const { event, params } = massageEvent.data;

      if (event === ECheckoutPageEvent.BACK_TO_STORE) {
        const offer = params?.offer;

        if (!offer || !params?.isOrderSucceed || !shouldDisplayProductsAnimation(offer)) return;

        const collectedProductsImages: string[] = offer?.products.map(
          ({ image }: { image: string }) => image
        );
        setCollectedProductsImages(collectedProductsImages);
      }
    };

    window.addEventListener('message', eventHandler);

    return () => {
      window.removeEventListener('message', eventHandler);
    };
  }, []);

  return (
    <AnimatePresence>
      {isExiting && (
        <StyledProductsAnimationWrapper
          className={'products-collect-animation'}
          transition={{ type: 'spring', stiffness: 100, damping: 25 }}
        >
          <AnimatePresence onExitComplete={() => setIsExiting(false)}>
            {collectedProductsImages && collectedProductsImages.length > 0 && (
              <motion.div
                exit={{
                  y: '-300%',
                  filter: 'blur(5px)',
                  transition: {
                    y: { duration: 0.2 },
                    filter: { duration: 0 },
                  },
                }}
              >
                {collectedProductsImages?.map((productImg) => (
                  <motion.img
                    key={productImg}
                    src={productImg}
                    alt={productImg}
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    transition={{
                      type: 'spring',
                      bounce: 0.5,
                      damping: 5,
                      mass: 0.5,
                      stiffness: 50,
                    }}
                    exit={{
                      scaleX: 0.7,
                      transition: {
                        scaleX: { duration: 0.1 },
                      },
                    }}
                    style={{ width: '200px', height: 'auto' }}
                  />
                ))}
              </motion.div>
            )}
          </AnimatePresence>
        </StyledProductsAnimationWrapper>
      )}
    </AnimatePresence>
  );
};
