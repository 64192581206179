import { useObservable } from '../observable';
import { appStateService } from '../app.state.service';
import useApi from 'hooks/useApi';
import { BootResponse, OrderData } from 'constants/apiResponses.types';
import { useMemo } from 'react';
import { EOfferType } from 'constants/enums';

export const useAppState = () => {
  const API = useApi({});
  const publisherMetaData = API.getPublisherMeta.data as BootResponse;

  const productsAnimationPurchase = useMemo(
    () => publisherMetaData?.featureFlags?.store_animation_after_purchases,
    [publisherMetaData?.featureFlags?.store_animation_after_purchases]
  );

  const productsAnimationCollect = useMemo(
    () =>
      publisherMetaData?.featureFlags?.store_collect_animation_post_free_collect_promotions_popups,
    [publisherMetaData?.featureFlags?.store_collect_animation_post_free_collect_promotions_popups]
  );

  const shouldDisplayProductsAnimation = (order: OrderData): boolean => {
    const price = order.price;
    // ACDEV-10360: todo: replace after be change
    // const isRollingOffer = order.offerType === EOfferType.ROLLING_OFFER;
    const isRollingOffer =
      appStateService.selectedOffer.get()?.offerType === EOfferType.ROLLING_OFFER;

    return (
      isRollingOffer ||
      (price === 0 && productsAnimationCollect) ||
      (price !== 0 && productsAnimationPurchase)
    );
  };

  return {
    collectedProductsImages: useObservable(appStateService.collectedProductsImages),
    displayLoader: useObservable(appStateService.displayLoader),
    showConfetti: useObservable(appStateService.showConfetti),
    selectedOffer: useObservable(appStateService.selectedOffer),
    failedHashValidation: useObservable(appStateService.failedHashValidation),
    backgroundProps: useObservable(appStateService.backgroundProps),
    isIntegrationProfilesEnabled: useObservable(appStateService.isIntegrationProfilesEnabled),
    setCollectedProductsImages: appStateService.setCollectedProductsImages,
    setDisplayLoader: appStateService.setDisplayLoader,
    setShowConfetti: appStateService.setShowConfetti,
    setSelectedOffer: appStateService.setSelectedOffer,
    setFailedHashValidation: appStateService.setFailedHashValidation,
    setBackgroundProps: appStateService.setBackgroundProps,
    setIsIntegrationProfilesEnabled: appStateService.setIsIntegrationProfilesEnabled,
    shouldDisplayProductsAnimation,
  };
};
