import React, { useMemo } from 'react';
import { ELoginMode } from '../../../constants/enums';
import { StyledLoginContainer } from '../style';
import { UsernamePasswordMode } from '../LoginMode/username';
import { LoginIdMode } from '../LoginMode/loginId';
import { OtpMode } from '../LoginMode/OTP';
import { ProvidersMode } from '../LoginMode/providers';
import { useLoginPage } from '../useLoginPage.hook';
import { useTranslation } from 'react-i18next';
import { useLoginState } from 'state/hooks/login.state.hook';
import { Routes, Route } from 'react-router-dom';

interface ILoginButtonBlocksProps {
  maxWidth?: string;
}

export const LoginButtonBlocks: React.FC<ILoginButtonBlocksProps> = ({ maxWidth = '400px' }) => {
  const { openInfoModal, mode, hasConsent } = useLoginState();
  const { publisherMetaData, isWebview } = useLoginPage();
  const { t } = useTranslation();

  const openHowToFindId = () => {
    openInfoModal(publisherMetaData.integration.playersAuthentication.userTokenUrl);
  };

  const showUserTokenLink = useMemo(
    () =>
      (mode === ELoginMode.PROVIDERS || mode === ELoginMode.TOKEN_ID) &&
      publisherMetaData.integration.playersAuthentication.userTokenUrl &&
      publisherMetaData.integration.playersAuthentication.userTokenText &&
      publisherMetaData.integration.playersAuthentication.userTokenOn &&
      !isWebview,
    [publisherMetaData.integration.playersAuthentication, isWebview]
  );

  return (
    <StyledLoginContainer maxWidth={maxWidth} disabled={!hasConsent}>
      <Routes>
        <Route path="/tokenId" element={<LoginIdMode />} />
        <Route path="/username" element={<UsernamePasswordMode />} />
        <Route path="/otp" element={<OtpMode />} />
        <Route path="/*" element={<ProvidersMode />} />
      </Routes>
      {showUserTokenLink && (
        <p className="userTokenLink" onClick={openHowToFindId}>
          {t('login.canNotFindPlayerId')}
        </p>
      )}
    </StyledLoginContainer>
  );
};
