import '../style.scss';
import { Box, Button, Modal, Typography } from '@mui/material';
import { StyledAccountApprovalModal, StyledPlayerDetails } from './style';
import { useTranslation } from 'react-i18next';
import { localStorageUtil } from 'utils';
import { EEventsType, ELocalStorageKeys } from 'constants/enums';
import { BootResponse } from 'constants/apiResponses.types';
import useApi from 'hooks/useApi';
import { useEffect, useMemo, useState } from 'react';
import useCustomEvents from 'hooks/useCustomEvents';

interface IAccountApprovalModalProps {
  isOpen: boolean;
  onApproval: () => void;
  onDisapproval: () => void;
}

export const AccountApprovalModal: React.FC<IAccountApprovalModalProps> = ({
  isOpen,
  onApproval,
  onDisapproval,
}) => {
  const { t } = useTranslation();
  const API = useApi({});
  const customEvents = useCustomEvents();
  const publisherMetaData = API.getPublisherMeta.data as BootResponse;
  const [picLoaded, setPicLoaded] = useState(false);

  useEffect(() => {
    if (!isOpen) return;
    customEvents.sendCustomEvent(EEventsType.DEEPLINK_ACCOUNT_APPROVAL_DISPLAYED, {
      approved_account: true,
      source: 'deeplink',
      source_type: 'dynamo',
    });
  }, [isOpen]);

  const accountApprovalModal = useMemo(
    () => publisherMetaData.storeTheme.general?.accountApprovalModal,
    [publisherMetaData.storeTheme?.general?.accountApprovalModal]
  );

  const playerName = localStorageUtil.get(ELocalStorageKeys.PLAYER_DATA)?.playerName;
  const playerImage =
    localStorageUtil.get(ELocalStorageKeys.PLAYER_DATA)?.profileImageUrl ||
    'https://media.appcharge.com/defaults/profile.png';

  return (
    <Modal open={isOpen}>
      <StyledAccountApprovalModal
        gap={'12px'}
        backgroundImage={accountApprovalModal?.backgroundImage}
        backgroundColor={
          accountApprovalModal?.backgroundColor || {
            colorOne: '#80809d',
          }
        }
        borderWidth={accountApprovalModal?.borderWidth}
        borderColor={accountApprovalModal?.borderColor}
        buttonBackgroundColor={
          accountApprovalModal?.buttonBackgroundColor || {
            colorOne: '#46B2B0',
          }
        }
        buttonTextColor={accountApprovalModal?.buttonTextColor}
        textColor={accountApprovalModal?.textColor}
        as={Box}
      >
        <Typography variant="h6" component="h2" fontSize={18} fontWeight={600}>
          {t('accountApprovalModal.title')}
        </Typography>
        <StyledPlayerDetails gap={'4px'}>
          <div className="player-image">
            <img
              alt="profile"
              className="profileContainer__img"
              src={playerImage}
              style={{ display: picLoaded ? 'unset' : 'none' }}
              onLoad={() => setPicLoaded(true)}
            />
            <img
              alt="profile"
              className="profileContainer__img"
              src="https://media.appcharge.com/defaults/old-profile.png"
              style={{ display: picLoaded ? 'none' : 'unset' }}
            />
          </div>
          {playerName && (
            <Typography variant="h6" component="h2" fontSize={14} fontWeight={500}>
              {playerName}
            </Typography>
          )}
        </StyledPlayerDetails>
        <Button className="approve-button" onClick={onApproval}>
          {t('accountApprovalModal.approve')}
        </Button>
        <Button className="disapprove-button" variant="text" onClick={onDisapproval}>
          {t('accountApprovalModal.disapprove')}
        </Button>
        <Box>
          <Typography
            color="white"
            className="footer"
            sx={{
              fontSize: '10px',
              textAlign: 'center',
            }}
          >
            {t('accountApprovalModal.byClicking')}{' '}
            <a href={publisherMetaData.publisher.privacyPolicyUrl} target="_blank" rel="noreferrer">
              {t('accountApprovalModal.privacyPolicy')}
            </a>{' '}
            {t('and')}{' '}
            <a
              href={publisherMetaData.publisher.termsAndConditionsUrl}
              target="_blank"
              rel="noreferrer"
            >
              {t('accountApprovalModal.termsAndConditions')}
            </a>
          </Typography>
        </Box>
      </StyledAccountApprovalModal>
    </Modal>
  );
};
